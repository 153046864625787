<template>
    <Auth :clear="true">
        <section class="page-error-view mt-card">
            <div class="logo-sesi">
                <ApplicationLogo />
            </div>

            <div class="disconnected-card">
                <span class="icon-page-error mb-2">
                    <CoffeeIcon />
                </span>

                <div class="text-center mb-1">
                    <h2 class="text-center">Você foi desconectado!</h2>
                    <p class="text-center">
                        Sua sessão expirou. Faça novamente o login para acessar o Cliente Sesi+Saúde.
                    </p>
                </div>

                <button @click="handleRedirectToLogin" type="button" class="btn button-page-error btn-block">
                    Ir para o login!
                </button>
            </div>
        </section>
    </Auth>
</template>

<script>
import Auth from "@/views/pages/authentication/BaseAuth/Auth";
import { CoffeeIcon } from 'vue-feather-icons'
import ApplicationLogo from "@/views/components/custom/logo-sesi/ApplicationLogo";

export default {
    components: {
        Auth,
        CoffeeIcon,
        ApplicationLogo,
    },
    data() {
        return {
            sideImg: require('@/assets/images/pages/login-v2.svg'),
        }
    },

    methods: {
        handleRedirectToLogin() {
            this.$store.dispatch('sessions/logout');
            this.$router.replace({ name: 'auth-login' });
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-error.scss';
</style>
